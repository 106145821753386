import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import VideoTextCard from '../components/VideoTextCard';
import HomeFeatures from '../components/HomeFeatures';
import TitleWithBgSection from '../components/TitleWithBgSection';
import DMHubContentList from '../components/DMHubContentList';
import OpenHub from '../components/OpenHub';
import RealEnterpriseProducts from '../components/RealEnterpriseProducts';
import HomeLogos from '../components/HomeLogos';
import BlockIconText from '../components/BlockIconText';
// import CloudBanner from '../images/cloud-banner-image.png';
import CloudBanner from '../images/dm-banner.svg';
import IconText from '../components/IconText';

function CustomIconText(props) {
  return (
    <Col span={24} className="icon-text-col">
      <IconText {...props} />
    </Col>
  );
}

CustomIconText.propTypes = {
  iconId: PropTypes.string,
  iconSize: PropTypes.number,
  content: PropTypes.string,
  title: PropTypes.string,
};

// TODO 这个页面的banner要不要加点击显示视频弹框的功能？
export default () => (
  <Layout current="products" subCurrent="marketingcloud">
    <ProductsBanner
      title="Convertlab 一体化营销云"
      img={CloudBanner}
      imgHeight={338}
      imgWidth={571}
      sectionId="marketingcloud"
      // video="https://cltemplate.oss-cn-hangzhou.aliyuncs.com/video/dmhub.mp4"
      // videoId="cl-mcloud"
      // cover=""
    />
    <VideoTextCard
      title="重新定义数字化营销"
      video="https://cltemplate.oss-cn-hangzhou.aliyuncs.com/video/dmhub.mp4"
    >
      <BlockIconText content="Convertlab 一体化营销云覆盖从广告投放、客户数据管理、营销自动化、营销内容和体验、用户运营和销售协同的完整营销运营体系，帮助您有效洞察营销业务、提升业绩增长，实现商业模式的数字化转型" />
    </VideoTextCard>
    <HomeFeatures title="Convertlab 营销云" subTitle="全链接、全场景、全生态" />
    <TitleWithBgSection title="丰富的项目实践经验和方法论">
      <>
        <CustomIconText
          title="数据驱动营销增长DIA模型"
          content="通过建立全渠道客户管理体系，业务数据及流程治理，有效提高营销运营效率、降低营销决策失误。营销活动可跟踪，结果可分析可归因，强大的AI和自动化技术赋能企业营销增长。"
          iconId="iconmeth-1"
          iconSize={97}
        />
        <CustomIconText
          title="PSI法则"
          content="以业务价值和场景为导向的，用于内部的销售和解决方案方法 PSI（Profiling，Scoping，Implementation）"
          iconId="iconmeth-2"
          iconSize={104}
        />
        <CustomIconText
          title="3A敏捷营销方法论"
          content="敏捷营销运营的 3A（Analysis，Action，Agility）方法"
          iconId="iconmeth-3"
          iconSize={80}
        />
      </>
    </TitleWithBgSection>
    <DMHubContentList />
    <RealEnterpriseProducts />
    <OpenHub />
    <HomeLogos />
  </Layout>
);
