import React from 'react';
import { Button } from 'antd';
import Container from './Container';
import ApplyButton from './ApplyButton';

export default () => {
  return (
    <section id="open-hub" className="open-hub">
      <Container>
        <div className="inner">
          <h3>Open Hub 开放平台</h3>
          <h5>所有企业的业务都有独特的一面，除了标准产品能力外，Convertlab营销云更具备Open Hub开发平台，为企业的个性化需求提供完美支持。有能力的企业可以自研平台插件满足需求。基于Open Hub能力，Convertlab营销云更提供了插件marketplace，众多ISV提供了很多场景扩展应用，供企业选择。</h5>
          <div className="buttons">
            <ApplyButton size="large" buttonPosition="middle">
              申请试用
            </ApplyButton>
            <Button className="get-detail">
              <a href="/openhub/">了解详情</a>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};
