import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import Container from './Container';

function TitleWithBgSection({ title, description, gutter = 0, children }) {
  return (
    <section id="title-with-bg-section" className="title-with-bg-section">
      <Container>
        <h3 className={!description ? 'no-description' : ''}>{title}</h3>
        {description && <h5>{description}</h5>}
        <Row className="content-row" gutter={gutter}>{children}</Row>
      </Container>
    </section>
  );
};

TitleWithBgSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  gutter: PropTypes.number,
  children: PropTypes.node,
};

export default TitleWithBgSection;
