import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Container from './Container';
import IconText from './IconText';

function CustomIconText({ title, iconId, content }) {
  return (
    <Col xl={12} sm={24} className="icon-text-col">
      <IconText
        title={title}
        content={content}
        iconDom={<div className={`icon-dom iconfont font-50 white icon${iconId}`} />}
        iconPosition="left-top"
      />
    </Col>
  );
}

CustomIconText.propTypes = {
  iconId: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
};

export default function RealEnterpriseProducts() {
  return (
    <section id="real-enterprise-products" className="real-enterprise-products">
      <Container>
        <div>
          <h3 className="title">真正的企业级产品</h3>
          <p className="description">
            Convertlab营销云的技术架构在数据安全、信息保护、开放接口、大数据高并发下的稳定性等方面有充足的考虑
          </p>
          <p className="description">真正满足最大数据规模的企业营销需求</p>
        </div>
        <Row>
          <CustomIconText
            title="数据安全与个人信息保护"
            content="在和客户持续沟通和营销的过程中，数据安全和个人信息保护的合规是必须要满足的要求。 Convertlab营销云从产品设计、技术架构、系统运维和服务过程全方面确保数据安全符合SaaS安全性合规和GDPR合规，并已获得 ISO 27001 国际信息安全认证。"
            iconId="shield"
          />
          <CustomIconText
            title="多级数据权限管理"
            content="Convertlab营销云完整的多级数据权限控制能力，可以支持总部、区域、分公司、终端等多级别独立控制数据权限，并且对于数据的不同等级操作(查看、修改、删除、导出等)可分开控制。另外可定义数据和用户的归属关系 。"
            iconId="data"
          />
          <CustomIconText
            title="关键信息加密"
            content="对于属于个人信息保护条例规定的个人信息（手机号码，地址等），Convertlab营销云提供存储层的数据加密。该方式可以有效防止在数据存储处发生数据泄露的情况下也不会造成个人信息的泄露。"
            iconId="lock"
          />
          <CustomIconText
            title="集成企业内部认证系统"
            content="大型企业往往自建内部用户认证系统， Convertlab营销云可实现对常见的企业内部认证系统的支持和集成。在登录企业内部用户系统后即可直接进入Convertlab营销云系统，实现 SSO和SAML。"
            iconId="user-checked"
          />
          <CustomIconText
            title="支持多种部署方式"
            content={
              <>
                <h5>Convertlab营销云支持多种部署方式，可根据企业实际情况和需要选择：</h5>
                <h5>- SaaS 方式</h5>
                <h5>- 公有云私有部署</h5>
                <h5>- 本地部署</h5>
              </>
            }
            iconId="blocks"
          />
          <CustomIconText
            title="企业级运营能力"
            content={
              <>
                <h5>- 高弹性架构支持超大数据量</h5>
                <h5>- 高性能及实时计算</h5>
                <h5>- 高可用性及服务级别保障</h5>
                <h5>- 专业的技术支持和服务团队</h5>
              </>
            }
            iconId="dots-circle"
          />
        </Row>
      </Container>
    </section>
  );
};